body {
    background-color: #000000; 
    /* font-family: 'Courier New', monospace !important;Using Roboto font for general text */
}

.boxcontainer {
    margin-top: 30px;
    padding: 20px;
}

.listAll {
    list-style: none;
    padding: 0;
}

.heading {
    color: #00ff00; 
    font-size: 24px; 
    border-radius: 5px;
    margin-bottom: 10px;
    text-transform: uppercase; 
    text-align: center; 
    text-shadow: 0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00; 
    font-family: 'Courier New', Courier, monospace;
    animation: glitch 2s infinite linear alternate;
}

.all-items {
    margin-bottom: 2px;
}

.website-name {
    font-weight: 400;
    margin-right: 3px;
    color: #ffffff;
}

.website-link {
    text-decoration: none;
    color: #00ff00;
    font-weight: 400 !important;
    margin-left: 3px;
    transition: margin-left 0.3s ease;
}

.website-link:hover {
    margin-left: 0;
}

.verified {
    color: #ff0000; 
    margin-left: 7px;
    font-size: 0.8em;
    transform: rotate(-9deg);
}

@keyframes glitch {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translate(-2px, 2px);
    }
    40% {
        transform: translate(2px, -2px);
    }
    60% {
        transform: translate(-2px, 2px);
    }
    80% {
        transform: translate(2px, -2px);
    }
    100% {
        transform: translate(0);
    }
}

@media only screen and (max-width: 600px) {
    body {
        font-family: 'Roboto', sans-serif !important;
    }
    .boxcontainer {
        padding: 10px;
    }

    
  .back-to-top-button {
   
    text-shadow: 0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00; 
  }

    .heading {
        font-size: 20px; 
        margin-bottom: 5px;
    }

    .all-items {
        margin-bottom: -4px;
    }

    .website-name {
        margin-right: 1px;
        font-size: 11px !important; 
    }

    .verified {
        color: #ff0000; 
        margin-left: 7px;
        font-size: 9px;
        transform: rotate(-9deg) !important;
    }

    .website-link {
        font-size: 11px; 
    }
  
    .website-name {
        font-weight: bold;
        margin-right: 3px; 
        font-size: 13px;
    }
}


/* contactus page css  */

.container-form {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 40px;
  }

  .form-container {
    background-color: #0f0e0e;
    padding: 20px;
    border: 1px solid #0f0; 
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(132, 190, 132);
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
  }
  
  label {
    font-size: 1.2em;
    margin-bottom: 5px;
    color: #0f0; 
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: calc(100% - 22px); 
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid rgb(102, 168, 102);
    border-radius: 5px;
    background-color: #111;
    color: #0f0;
  }
  
  input[type="submit"] {
    width: 50%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #0f0;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  input[type="submit"]:hover {
    background-color: #0a0;
  }
  

  .back-to-top-button {
    position: fixed;
    background: none !important;
 
    font-family: 'Courier New', Courier, monospace;
    animation: glitch 2s infinite linear alternate;
    color: #00ff00;
    rotate: -90deg;
    bottom: 20px;
    right: 20px;
    border: none;
    border-radius: 50%;
    font-size: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  

/* Loader.css */

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000;
  }
  
  .loader {
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 255, 0, 0.5); 
    border-radius: 50%;
    animation: pulse 1.5s ease-in-out infinite;
  }
  
  .loader:before {
    animation-delay: 0.5s;
  }
  
  .loader:after {
    animation-delay: 1s;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.5);
      opacity: 0.5;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.5);
      opacity: 0.5;
    }
  }
  
  .loader-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    border: 3px solid transparent;
    border-top-color: rgba(0, 255, 0, 0.8); 
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
      border-color: transparent;
    }
    100% {
      transform: rotate(360deg);
      border-color: rgba(0, 255, 0, 0.8); 
    }
  }
  
  /* Text color */
  .loader-container,
  .loader-container *,
  .loader:before,
  .loader:after {
    color: #0f0;
  }
  