.container {
  max-width: 600px;
  margin: 0 auto;
}


.content-div {
  background-color: #000000;
  padding: 10px 0px;
  border: 2px dotted #0f0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(36, 37, 36);
  margin: 30px auto;
  height:70px;
}
.content-div h1 {
  font-size: 25px;
  text-align: center;
  color: #0f0;
  text-shadow: 0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00; 
  font-family: 'Courier New', Courier, monospace;
  margin-top: 5px;
}
.menu-container {
    width: 70%;
    margin: auto;
    margin-top: 50px;
  }
  
  .menu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  
  .menu-list li {
    list-style-type: none;
    margin: 5px;
  
 text-align: center;
 
  }





  
  .menu-list li a {
    text-decoration: none;
    color: rgb(0, 255, 0);
    font-size: 18px;
    transition: color 0.3s; 
 
  }
  
  .menu-list li a:hover {
    color: darkgreen; /* Change link color on hover */
  }
  
  .separator {
    margin: 0 5px;
    
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .menu-container {
      width: 90%;
    }
    .menu-list li a {
      text-decoration: none;
      color: rgb(0, 255, 0);
      font-size: 0.9em;
      transition: color 0.3s;

  }
  .menu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: -11px;
    margin: -10px;
}
.menu-list li {
  list-style-type: none;
  margin: 2px;
}

  }

  .marqueetag{
    color: #ffffff ;
    background-color: #000000;
    padding: 5px;
    
  }

  .updating{
   margin: 0px;
   font-size: 13px;
   text-shadow: 0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00; 
   font-family: 'Courier New', Courier, monospace;
   color: #ffffff;
   margin-top: 10px;
   text-transform: capitalize;
   text-align: center;

  }

  .ad img{
    border-radius: 10px;
    width: 100%; 
    background-color: #f0f0f0;
    height: 100px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
 
  }
  .ad{
    margin-bottom:10px;
  }
  /* Media query for responsiveness on smaller screens */
  @media (max-width: 768px) {
    .ad {
  
      height: 100px; 
    }
  }
  