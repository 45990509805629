.hacker-footer {
  background-color: #0e0e0e;
  color: #00ff00;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-bottom {
  text-align: center;
  margin-top: auto; /* Push the footer to the bottom */
}

.footer-bottom p {
  margin-bottom: 20px;
}

.illuminati-symbol {
  font-size: 60px;
  color: #00ff00;
}
