/* AboutUsPage.css */

.about-us-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Courier New', Courier, monospace;
    color: #0f0;
  }
  
  .about-us-title {
    text-align: center;
    font-size: 2rem;
  }
  
  .about-us-content {
    margin-top: 20px;
  }
  
  .about-us-section {
    font-size: 1.5rem;
  }
  
  .about-us-content p,
  .about-us-content ul {
    margin-bottom: 10px;
  }
  
  .about-us-content ul {
    list-style-type: none;
  }
  
  .about-us-content ul li:before {
    content: "\2022";  /* bullet point */
    color: #0f0;
    font-size: 1.2em;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  